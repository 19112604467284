// src/slice/navButtonSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const navButtonSlice = createSlice({
  name: "navButton",
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggle } = navButtonSlice.actions;

export default navButtonSlice.reducer;
