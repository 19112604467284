import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../constants/routes";

const MoonHero = () => {
  return (
    <div className="moonhero">
      <div className="container">
        <div className="moonHeroImg"></div>
        <div className="dlButton">
          <a href="/moon.zip">ダウンロード⬇</a>
        </div>
        <p className="desc">
          <Link to={ROUTES.MOON_EULA}>利用規約</Link> | 無料 | Windows10対応
        </p>
        <p className="desc" style={{ fontSize: "14px" }}>
          ※発行元の署名をつけていない為、Microsoft
          Defender等で実行時に警告がでます。
        </p>
        <p className="desc" style={{ fontSize: "14px", paddingTop: "0px" }}>
          今後もフリーソフトとして提供する為に費用のかかる署名をつけておりません。
        </p>
        <p className="desc" style={{ fontSize: "14px", paddingTop: "0px" }}>
          何卒、ご容赦ください。
        </p>
      </div>
    </div>
  );
};

export default MoonHero;
