// store.js
import { configureStore } from "@reduxjs/toolkit";
import headerReducer from "../slice/headerSlice";
import navButtonReducer from "../slice/navButtonSlice";
import loadingReducer from "../slice/loadingSlice";

export default configureStore({
  reducer: {
    navButton: navButtonReducer,
    header: headerReducer,
    loading: loadingReducer,
  },
});
