import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import "../css/MoonEulaPage.css";

const MoonEulaPage = () => {
  return (
    <div className="moonEulaPageContainer">
      <Header />
      <p className="title">アプリケーション名</p>
      <p className="desc">moon(MirroringCaptureApp) 1.0.0 beta</p>
      <p className="desc">moonは付箋のようなキャプチャーソフトです。</p>
      <p className="desc">
        デスクトップ上に表示されているものを簡単に切り抜いて、デスクトップ上にそのまま表示するソフトです。
      </p>
      <p className="desc">
        表示したイメージは保存やコピー、ペーストが可能になっています。
      </p>
      <p className="title">動作環境</p>
      <p className="desc"> Windows 10 .NET Framework 6.0対象</p>
      <p className="desc">
        MirroringCaptureApp.exeをクリックするとメイン画面が表示されます。
      </p>
      <p className="title">インストール</p>
      <p className="desc">ダウンロードしたファイルを解凍して下さい。</p>
      <p className="desc">
        これらのファイルを適当なフォルダに置いてMirroringCaptureApp.exeを実行して下さい。
      </p>
      <p className="title">アンインストール</p>
      <p className="desc">フォルダ毎、削除してください。</p>
      <p className="title">著作権その他</p>
      <p className="desc">
        このソフトはフリーソフトですので無料でお使いになれますが以下の点、ご注意ください。
      </p>
      <p className="desc">
        moon(MirroringCaptureApp) に関する全ての著作権はelbaupが保有しています。
      </p>
      <p className="desc">
        許可の無い二次配布、複製、改変、リバースエンジニアリング、逆コンパイルを禁じます。
      </p>
      <p className="title">免責事項</p>
      <p className="desc">
        本ソフトを運用した結果について、作成者のelbaupは一切の責任を負えないのでご了承ください。
      </p>
      <p className="title">更新履歴</p>
      <p className="desc">2023/10/16 1.0.0 beta</p>
      <p className="desc" style={{ marginBottom: "50px" }}>
        ベータ版リリース
      </p>

      <Footer />
    </div>
  );
};

export default MoonEulaPage;
