import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Style from "./Service.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RandomTextAnimation from "../atoms/RandomTextAnimation";
gsap.registerPlugin(ScrollTrigger);

const Service: React.FC = () => {
  const h2Ref = useRef<HTMLHeadingElement>(null);
  const didEffect = React.useRef(false);

  useEffect(() => {
    if (didEffect.current) return;
    didEffect.current = true;
    if (h2Ref.current) {
      gsap.from(h2Ref.current, {
        duration: 1.5,
        autoAlpha: 0,
        y: 100,
        ease: "none",
        scrollTrigger: {
          trigger: h2Ref.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
          scrub: false, // これによりスクロールの動きにアニメーションが連動する
        },
      });
    }
  }, []);

  return (
    <div className={Style.service}>
      <div className={Style.container}>
        <div ref={h2Ref}>
          <img src="/service_img.png" alt="code" />
        </div>
        <div className={`${Style.textBlock} ${Style.boxDesign}`}>
          <h2>
            <RandomTextAnimation
              targetText="Service"
              duration={5000}
              interval={100}
            />
          </h2>
          <div className={Style.pBlock}>
            <div className={Style.pBlock1}>
              <RandomTextAnimation
                targetText="最新のテクノロジーで最先端の開発へ。"
                duration={5000}
                interval={100}
              />
            </div>
            <div className={Style.pBlock2}>
              <RandomTextAnimation
                targetText="私達は柔軟で自由なサービスを創ります。"
                duration={5000}
                interval={100}
              />
            </div>
            <div className={Style.blockContena}>
              <div className={Style.listBlock}>
                <ul>
                  <li>TypeScrpt</li>
                  <li>JavaScrpt</li>
                  <li>React</li>
                  <li>Node.js</li>
                  <li>Next.js</li>
                </ul>
              </div>
              <div className={Style.listBlock}>
                <ul>
                  <li>Java</li>
                  <li>Springboot</li>
                  <li>Oracle</li>
                  <li>PostgreSQL</li>
                  <li>MySQL</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={Style.servicebtnLink}>
            <Link to="/service">View More.</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
