import React from "react";
import Style from "./MenuOverlay.module.scss";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { NavState } from "../../state/rootState";
import { motion } from "framer-motion";
import logoSvg from "../../svg/logo.svg";
import ROUTES from "../../constants/routes";

const MenuOverlay = () => {
  const isNavOpen = useSelector((state: NavState) => state.navButton.isOpen);

  const variants = {
    open: { top: 0 }, // オーバレイが表示される状態
    closed: { top: "-100%" }, // オーバレイが非表示の状態
  };

  return (
    <motion.div
      className={Style.overlay}
      initial="closed" // 初期状態
      animate={isNavOpen ? "open" : "closed"} // アニメーションの状態
      variants={variants} // アニメーションの変化を定義
      transition={{ duration: 0.5 }} // アニメーションの持続時間とタイプ
    >
      <div className={Style.continer}>
        <nav className={Style.headerNav}>
          <ul className={`${Style.headerNavList}`}>
            <li className={Style.headerNavListItem}>
              <Link to={ROUTES.ABOUT} className={`${Style.target}`}>
                About us
              </Link>
            </li>
            <li className={Style.headerNavListItem}>
              <Link to={ROUTES.SERVICE} className={`${Style.target}`}>
                Service
              </Link>
            </li>
            <li className={Style.headerNavListItem}>
              <Link to={ROUTES.CONTACT} className={`${Style.target}  `}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div className={Style.cardContainer}>
          <Link to={ROUTES.CONTACT} style={{ textDecoration: "underline" }}>
            <div className={Style.card}>
              <div className={Style.logTitle}>
                <svg
                  role="img"
                  viewBox="0 0 1139 325"
                  className={Style.svgSmall}
                >
                  <use xlinkHref={`${logoSvg}#logo-hr`}></use>
                </svg>
                <h1>Elbaup!!</h1>
              </div>
              <h2>大阪府高槻市赤大路町11-8</h2>
              <h3>代表：比嘉 龍</h3>
              <div className="mt-2" style={{ color: "#4169e1" }}></div>

              <div className={Style.circle}></div>
              <div className={Style.circle}></div>
            </div>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default MenuOverlay;
