import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Style from "./Contact.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RandomTextAnimation from "../atoms/RandomTextAnimation";
gsap.registerPlugin(ScrollTrigger);

interface ContactProps {
  scrollTween: GSAPTween | null;
}

const Contact: React.FC<ContactProps> = ({ scrollTween }) => {
  const h2Ref = useRef<HTMLHeadingElement>(null);
  //  const didEffect = React.useRef(false);

  useLayoutEffect(() => {
    if (h2Ref.current && scrollTween) {
      gsap.from(h2Ref.current, {
        duration: 1,
        autoAlpha: 0,
        y: 20,
        ease: "none",
        scrollTrigger: {
          trigger: h2Ref.current,
          containerAnimation: scrollTween,
          markers: false,
          start: "left 70%",
          toggleActions: "play none none reverse",
          scrub: false, // これによりスクロールの動きにアニメーションが連動する
        },
      });
    }
  }, [scrollTween]);

  return (
    <div className={Style.contact}>
      <div className={Style.container} ref={h2Ref}>
        <img src="/contact.png" alt="code" />
        <div className={`${Style.textBlock} ${Style.boxDesign}`}>
          <h2 ref={h2Ref}>
            <RandomTextAnimation
              targetText="Contact us"
              duration={2000}
              interval={100}
            />
          </h2>
          <div className={Style.pBlock}>
            <div className={Style.pBlock1}>
              <RandomTextAnimation
                targetText="当社へのご相談はお問い合わせ"
                duration={1000}
                interval={50}
              />
            </div>
            <div className={Style.pBlock2}>
              <RandomTextAnimation
                targetText="フォームより承っております。"
                duration={1000}
                interval={50}
              />
            </div>
          </div>
          <div className={Style.contactbtnLink}>
            <Link to="/contact">Contact.</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
