import React, { useState, ChangeEvent, FormEvent } from "react";
import { FormData } from "../type/formType";
import axios from "axios";
import * as yup from "yup"; // yupをインポート

// バリデーションスキーマを定義
const schema = yup.object().shape({
  name: yup
    //文字列に対するバリデーション
    .string()
    //必須バリデーション
    .required("お名前は必須です。")
    //正規表現マッチのバリデーショーン
    .matches(/^\D*$/, "お名前に数字は使用できません。"),
  email: yup
    .string()
    .email("メールアドレスが無効です。")
    .required("メールアドレスは必須です。"),
  message: yup.string().required("お問い合わせ内容は必須です。"),
});

const ContactForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  //送信ボタンの制御ステータス
  const [submitting, setSubmitting] = useState(false);
  //送信結果のステータス
  const [submitStatus, setSubmitStatus] = useState("");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    console.log(`Field: ${name}, Value: '${value}'`); // 追加したログ出力
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitStatus("");

    try {
      // yupのバリデーション実行
      //abortEarly: false  全てのバリデーションエラーを収集し、一度に処理してまとめて表示
      await schema.validate(formData, { abortEarly: false });

      // サーバーサイドのエンドポイントにデータをPOSTで送信
      const response = await axios.post(
        "https://elbaup.com/api/contact",
        formData
      );

      // 応答の確認
      if (response.status === 200) {
        // 送信成功
        console.log("データの送信に成功:", response.data);
        // 送信成功後の処理（例: フォームのリセットや成功メッセージの表示など）
        setSubmitStatus("送信が完了しました。ありがとうございます！");
      } else {
        console.error("データの送信に失敗:", response.data);
        // 送信失敗時のエラー処理
        setSubmitStatus("送信に失敗しました。後ほど再度お試しください。");
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // バリデーションエラーメッセージを表示
        console.error("バリデーションエラー:", error.errors);
        setSubmitStatus(`エラー: ${error.errors.join(", ")}`);
      } else {
        // その他のエラー処理
        console.error("エラーが発生:", error);
        setSubmitStatus("送信に失敗しました。後ほど再度お試しください。");
      }
    }

    setSubmitting(false);
  };
  return (
    <div className="contactFromblock">
      <div className="contactFrom">
        <div className="contactTitle">
          <h2>Contact</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-label">
              <label>お名前</label>
              <span>必須</span>
            </div>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="お名前"
            />
          </div>
          <div className="form-row">
            <div className="form-label">
              <label>メールアドレス:</label>
              <span>必須</span>
            </div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-row">
            <div className="form-label">
              <label>お問い合わせ内容</label>
              <span>必須</span>
            </div>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={5}
              placeholder="お問い合わせ内容をこちらにご記入ください"
            />
          </div>
          <div className="form-row">
            <div className="form-label"></div>
            <button type="submit" disabled={submitting}>
              {submitting ? "送信中..." : "送信"}
            </button>
            {submitStatus && (
              <div className="submitStatuText">{submitStatus}</div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
