import React, { useCallback, useState } from "react";
import ExcelJS from "exceljs";
import Style from "./ExcelInput.module.scss";
import Button from "react-bootstrap/Button";

const ExcelInput = () => {
  const [fileName, setFileName] = useState<string | null>(null);
  //const [tableData, setTableData] = useState<JSX.Element | null>(null);
  const [images, setImages] = useState<string[]>([]); // 画像URLを格納するための状態

  const reset = () => {
    setFileName(null);
    // setTableData(null);
    setImages([]); // 画像データをリセット
    images.forEach((imageUrl) => URL.revokeObjectURL(imageUrl)); // 画像のURLをクリーンアップ
  };

  const downloadImages = () => {
    images.forEach((imageUrl, index) => {
      const a = document.createElement("a");
      a.href = imageUrl;
      a.download = `image-${index}.png`; // ダウンロードするファイルの名前
      document.body.appendChild(a); // DOMに追加
      a.click(); // ダウンロードをトリガー
      document.body.removeChild(a); // 追加した要素を削除
    });
  };

  //ドロップしたときに発火
  const onDrop = useCallback(async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];

      // 拡張子チェック
      const fileExtension = file.name.split(".").pop();
      if (fileExtension !== "xlsx" && fileExtension !== "xlsm") {
        alert("エラー: 有効なExcelファイル (.xlsx, .xlsm) のみ受け付けます。");
        return; // 以降の処理を中断
      }

      setFileName(file.name); // ファイル名を設定

      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === "string") {
          const buffer = new ArrayBuffer(e.target.result.length);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < e.target.result.length; i++) {
            view[i] = e.target.result.charCodeAt(i);
          }
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);
          //          const sheet = workbook.worksheets[0]; // 最初のシートを使用

          workbook.eachSheet((sheet, sheetId) => {
            //            console.log(`Sheet ID: ${sheetId}, Sheet Name: ${sheet.name}`);
            if (sheet.getImages && typeof sheet.getImages === "function") {
              const imageObjects = sheet.getImages();
              imageObjects.forEach((image) => {
                const imageId = image.imageId;
                const img = workbook.getImage(Number(imageId));
                if (img && img.buffer) {
                  const uint8ArrayData = new Uint8Array(img.buffer);
                  const blob = new Blob([uint8ArrayData], {
                    type: "image/png",
                  });
                  const imageUrl = URL.createObjectURL(blob);
                  setImages((prevImages) => [...prevImages, imageUrl]);
                }
              });
            }
          });
        }
      };
      reader.readAsBinaryString(file);
    }
  }, []);

  //ドラッグしている間発火
  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  return (
    <div className={Style.outPutContiner}>
      <div className={Style.siteArea}>
        <div className={Style.titletext}>
          <h2>Excelファイルから画像の抽出</h2>
          <div className={Style.textDesc}>
            <p>
              利用者のプライパシーを厳守しファイル・画像はサーバへ送信しておりません。
            </p>
            <p>
              すべてお使いのブラウザ内で処理を完結していますのでご安心してご利用ください。
            </p>
          </div>
        </div>
        <div className={Style.btnGroup}></div>
        <Button
          variant="danger"
          onClick={reset}
          style={{ marginRight: "10px" }}
        >
          読込リセット
        </Button>
        <Button variant="primary" onClick={downloadImages}>
          画像の一括ダウンロード
        </Button>
        {fileName ? (
          // fileNameがある場合、ファイル名を表示
          <div className={Style.fileName}>読み込まれたファイル: {fileName}</div>
        ) : (
          // fileNameがない場合、ドロップエリアを表示
          <div className={Style.dropArea}>
            <div
              onDrop={onDrop}
              onDragOver={onDragOver}
              style={{
                border: "1px dashed grey",
                padding: "20px",
                textAlign: "center",
                height: "300px",
              }}
            >
              ここにExcelファイルをドラッグ＆ドロップしてください
            </div>
          </div>
        )}
        <div className={Style.outputFiles}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              style={{ paddingBottom: "30px" }}
              alt="Excel Sheet"
            />
          ))}
        </div>
        <div className="card">
          <div className="card-header">個人情報について</div>
          <div className="card-body">
            <p className="card-text">
              ここで使用したExcelファイルとその内容は、当社のサーバにアップロードされません。
            </p>
            <p className="card-text">
              利用者のパソコンやスマホのブラウザ内でのみ処理が行われます。
            </p>
            <p className="card-text">
              当社がどのように利用したか知ることはできません。
            </p>
          </div>
        </div>
        <div
          className="card"
          style={{
            marginTop: "20px",
            marginBottom: "70px",
          }}
        >
          <div className="card-header">注意事項</div>
          <div className="card-body">
            <p className="card-text">
              Excelファイルにより、抽出しきれない画像や利用できないファイルがある可能性があります。
            </p>
            <p className="card-text">
              ご利用にあたって生じた損害を当社は一切の責任を持つことができません。ご容赦ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelInput;
