import WebGL from "../contents/webGL"; // WebGL コンポーネントをインポート
import styles from "./Hero.module.scss";
import React, { useEffect } from "react";
//import Vivus from "vivus";
import animeLogo from "../../svg/animeLogo.svg";

const Hero = () => {
  const didEffect = React.useRef(false);

  useEffect(() => {
    if (didEffect.current) return;
    didEffect.current = true;

    //    new Vivus(`${styles.animeLogo}`, {
    //      type: "oneByOne",
    //      duration: 500,
    //      file: animeLogo,
    //    });
  }, []);
  return (
    <div className="webGl" style={{ position: "relative", width: "98vw" }}>
      <WebGL />
      <div className={styles.hero}>
        <p className={styles.p1}>願いは叶う！！</p>
        <p className={styles.p2}>小さな事からはじめよう。</p>
        <div id={styles.animeLogo}>
          <img src={animeLogo} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
