import ExcelInput from "../components/contents/ExcelInput";
import Style from "./AboutUs.module.scss";
import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";

const ImageOutputPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={Style.aboutUsContainer}>
      <Header2 />
      <div style={{ height: "78px" }}></div>
      <ExcelInput />
      <Footer />
    </div>
  );
};

export default ImageOutputPage;
