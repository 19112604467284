import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import "../css/MoonPage.css";

const MoonDesc = () => {
  return (
    <div className="moonDescZone">
      <div className="container">
        <div className="moonDesc1">
          <div className="moonDesc1Img"></div>
          <div className="moonDesc1Txt">
            <p className="p1">邪魔にならない</p>
            <p className="p2">
              moonは切り取った画像以外の余計な情報を画面に表示しません。
            </p>
            <p className="p3">
              一時的に視覚をメモしておきたいけどデスクトップを占領するようなアプリはストレスがたまりますよね？{" "}
            </p>
            <p className="p4">moonはストレスフリーを追求します。</p>
          </div>
        </div>
        <div className="moonDesc1">
          <div className="moonDesc2Img"></div>
          <div className="moonDesc1Txt">
            <p className="p1">付箋のように利用できる</p>
            <p className="p2">
              キャプチャー画像をダブルクリックすることで縮小することができます。
            </p>
            <p className="p3">
              邪魔なときはデスクトップの四隅にでも畳んでおいてください。
            </p>
            <p className="p4">コピーと貼り付けができます。</p>
            <p className="p4">
              Excelファイルの作業はもちろん、TwitterなどのSNSに活用できます。
            </p>
          </div>
        </div>
        <div className="moonDesc3">
          <p className="p1">お願い</p>
          <p className="p2">
            よりよいアプリケーションにしていく為にもみなさまの声がより多く必要です。
          </p>
          <p className="p3"></p>
          <p className="p4">
            ご要望やご意見を随時、募集していますのでお気軽にお問い合わせください。
          </p>
        </div>
      </div>
    </div>
  );
};
export default MoonDesc;
