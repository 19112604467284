import HeaderStyle from "./Header2.module.scss";
import logoSvg from "../../svg/logo.svg";
import React from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../constants/routes";

const Header2 = () => {
  return (
    <header className={HeaderStyle.header}>
      <div className={HeaderStyle.headerLogo}>
        <Link to="/" className={HeaderStyle.headerLogoTarget}>
          <svg role="img" viewBox="0 0 1139 325">
            <title>Elbaup!!</title>
            <use xlinkHref={`${logoSvg}#logo-hr`}></use>
          </svg>
          <h2>Elbaup!!</h2>
        </Link>
      </div>
      <nav className={HeaderStyle.headerNav}>
        <ul className={`${HeaderStyle.headerNavList} ${HeaderStyle.uFonten}`}>
          <li className={HeaderStyle.headerNavListItem}>
            <Link
              to={ROUTES.ABOUT}
              className={`${HeaderStyle.target} ${HeaderStyle.uTargetfilter} `}
            >
              about
            </Link>
          </li>
          <li className={HeaderStyle.headerNavListItem}>
            <Link
              to={ROUTES.SERVICE}
              className={`${HeaderStyle.target} ${HeaderStyle.uTargetfilter} ${HeaderStyle.isHashOnclick}`}
            >
              service
            </Link>
          </li>
          <li className={HeaderStyle.headerNavListItem}>
            <Link
              to={ROUTES.CONTACT}
              className={`${HeaderStyle.target} ${HeaderStyle.uTargetfilter} `}
            >
              contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header2;
