import { useEffect } from "react";
import * as THREE from "three";

const WebGL = () => {
  useEffect(() => {
    // サイズを指定
    //横幅の計算
    const viewportWidth = window.innerWidth - 0;
    //高さの計算
    const headerHeight = 70; // ヘッダーの高さ
    const viewportHeight = window.innerHeight - headerHeight;

    // Three.jsの基本セットアップ
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf4f3ef); // 背景色を白に設定
    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );

    //新しい要素の作成
    const canvas = document.createElement("canvas");
    canvas.width = viewportWidth;
    canvas.height = viewportHeight;

    const heroElement = document.querySelector(".webGl") as HTMLElement | null;
    if (heroElement) {
      // hero 要素の最初の子要素を取得
      const firstChildOfHero = heroElement.firstChild;
      // canvas を hero 要素の最初の子要素の前に安全に挿入
      if (firstChildOfHero) {
        heroElement.insertBefore(canvas, firstChildOfHero);
      } else {
        heroElement.appendChild(canvas);
      }

      // レンダラーを作成
      const renderer = new THREE.WebGLRenderer({
        canvas,
      });
      renderer.setSize(viewportWidth, viewportHeight);
      // ウィンドウサイズ変更時にレンダラーのサイズを更新する関数
      const onWindowResize = () => {
        const newViewportWidth = window.innerWidth - 0;
        const newViewportHeight = window.innerHeight - headerHeight;

        renderer.setSize(newViewportWidth, newViewportHeight);
        camera.aspect = newViewportWidth / newViewportHeight;
        camera.updateProjectionMatrix();
      };
      // ウィンドウサイズ変更イベントリスナーを追加
      window.addEventListener("resize", onWindowResize, false);

      // ラインのマテリアル設定
      const material = new THREE.LineBasicMaterial({
        color: 0x71b33c,
      });

      // 初期パラメータ
      let a = 3,
        b = 4,
        c = 3;
      let t = 0;

      // ラインの作成とシーンへの追加
      const line = new THREE.Line(new THREE.BufferGeometry(), material);
      line.position.x += 0; // ラインを右に移動するためにX座標を増加
      scene.add(line);

      // カメラの位置設定
      camera.position.z = 3;
      camera.position.x = -0.1; // カメラを左に移動

      let lastTime = 0; // 前回のフレームのタイムスタンプ

      const animate = (time: number) => {
        requestAnimationFrame(animate);

        let deltaTime = time - lastTime; // 前回のフレームからの経過時間
        lastTime = time; // タイムスタンプを更新

        // デルタ時間を秒単位に変換
        deltaTime /= 1000;

        // パラメータの更新（デルタ時間に基づく）
        a += deltaTime * 1;
        b += deltaTime * 1;
        c += deltaTime * 1;
        t += deltaTime * 0.5;
        // リサージュ曲線の計算
        const points = [];
        for (let i = 0; i <= 200; i++) {
          const theta = (i / 200) * 2 * Math.PI;
          const x = Math.sin(a * theta + Math.PI / 2);
          const y = Math.sin(b * theta);
          const z = Math.sin(c * theta);
          points.push(new THREE.Vector3(x, y, z));
        }

        // ジオメトリの更新
        line.geometry.dispose();
        line.geometry = new THREE.BufferGeometry().setFromPoints(points);

        // 回転アニメーション
        line.rotation.x = t;
        line.rotation.y = t;

        // レンダリング
        renderer.render(scene, camera);
      };
      animate(0);

      // コンポーネントのアンマウント時に canvas を削除
      return () => {
        if (canvas.parentNode === heroElement) {
          heroElement.removeChild(canvas);
        }
        window.removeEventListener("resize", onWindowResize);
      };
    }
  }, []);

  return null;
};

export default WebGL;
