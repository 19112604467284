import MoonHero from "../components/MoonHero";
import MoonDesc from "../components/MoonDesc";
import "../css/MoonPage.css";
import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";

const MoonPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="moonPageContainer">
      <Header2 />
      <div style={{ height: "78px" }}></div>
      <MoonHero />
      <MoonDesc />
      <Footer />
    </div>
  );
};

export default MoonPage;
