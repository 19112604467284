import { Link } from "react-router-dom";
import ROUTES from "../constants/routes";

const ServiceHero = () => {
  return (
    <div className="servicehero">
      <div className="container">
        <div className="serviceTitle">
          <h2>Service</h2>
        </div>
        <Link to="/moon">
          <div className="moonImg"></div>
        </Link>
        <div className="moonDesc">
          <p>まるで付箋のようなキャプチャーソフト「moon」</p>
          <p>すべての作業の助けになります</p>
        </div>
        <div className="moonBtnLink">
          <Link to={ROUTES.MOON}>View More.</Link>
        </div>
        <div className="seviceSystemheroColor">
          <div className="seviceSystemhero">
            <h2>システム開発</h2>
            <div className="seviceSystemheroImg"></div>
          </div>
          <div className="seviceSystemherodDesc">
            <p className="p1">
              Java×SpringBootを用いた業務用システム開発
              <br />
              TypeScript×Reactを用いたモダンなフロントエンド開発
              <br />
              すべて幅広く対応できます。
            </p>
            <p className="p2">
              ご依頼・ご相談はお問合せフォームからお願いいたします。
            </p>
          </div>
          <div className="contactBtnLink">
            <Link to={ROUTES.CONTACT}>Contact.</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHero;
