import React, { useState, useEffect } from "react";
import Style from "./ImageContentsCard.module.scss";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { Button, Accordion, Tab, Tabs } from "react-bootstrap";

const ffmpeg = createFFmpeg({ log: true });

const ImageContentsCard: React.FC = () => {
  const [image, setImage] = useState<File | null>(null);
  const [output, setOutput] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logMessages, setLogMessages] = useState<string[]>([]);
  const [isConverting, setIsConverting] = useState<boolean>(false);
  const [outputFileName, setOutputFileName] = useState("");

  useEffect(() => {
    const load = async () => {
      if (!ffmpeg.isLoaded()) {
        setIsLoading(true);
        await ffmpeg.load();
        setIsLoading(false);
      }
    };
    ffmpeg.setLogger(({ type, message }) => {
      if (
        message === "ffmpeg-core loaded" ||
        message === "loading ffmpeg-core"
      ) {
        setLogMessages([message]);
      } else {
        setLogMessages([`${message}`]);
      }
    });
    load();
  }, []);

  const convertImage = async (fileType: string) => {
    if (image && !isConverting) {
      setIsConverting(true);
      const originalFileName = image.name.split(".").slice(0, -1).join(".");
      const outputFileName = `${originalFileName}.${fileType}`;
      setOutputFileName(outputFileName);

      ffmpeg.FS("writeFile", "input", await fetchFile(image));

      let ffmpegArgs = ["-i", "input", outputFileName];

      try {
        await ffmpeg.run(...ffmpegArgs);
        const data = ffmpeg.FS("readFile", outputFileName);
        const url = URL.createObjectURL(
          new Blob([data.buffer], { type: `image/${fileType}` })
        );
        setOutput(url);
      } catch (error) {
        setLogMessages(["変換エラー"]);
      } finally {
        setIsConverting(false);
      }
    }
  };

  const convertToJpeg = () => convertImage("jpeg");
  const convertToPng = () => convertImage("png");
  const convertToWebp = () => convertImage("webp");

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={Style.contents}>
      <div className={Style.container}>
        <div className={Style.titletext}>
          <h2>画像ファイルの変換</h2>
          <div className={Style.textDesc}>
            <p>
              利用者のプライバシーを守る為にファイル・画像をサーバへ送信しておりません。
            </p>
            <p>
              すべてお使いのブラウザ内（PC/携帯端末）で変換処理をしていますので安心してご利用ください。
            </p>
          </div>
        </div>
        <div className={Style.convertBox}>
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                setImage(e.target.files ? e.target.files[0] : null)
              }
            />
            <Tabs
              defaultActiveKey="jpeg"
              id="image-tab-example"
              className="mb-3 mt-3"
            >
              <Tab eventKey="jpeg" title="JPEG">
                <Button
                  variant="success"
                  style={{ marginTop: "10px" }}
                  onClick={convertToJpeg}
                  disabled={isConverting}
                >
                  JPEGに変換
                </Button>
              </Tab>
              <Tab eventKey="png" title="PNG">
                <Button
                  variant="success"
                  style={{ marginTop: "10px" }}
                  onClick={convertToPng}
                  disabled={isConverting}
                >
                  PNGに変換
                </Button>
              </Tab>
              <Tab eventKey="webp" title="WebP">
                <Button
                  variant="success"
                  style={{ marginTop: "10px" }}
                  onClick={convertToWebp}
                  disabled={isConverting}
                >
                  WebPに変換
                </Button>
              </Tab>
            </Tabs>
            <div className="logs" style={{ marginTop: "10px" }}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>変換状況の表示</Accordion.Header>
                  <Accordion.Body>
                    {logMessages.length > 0 && <p>{logMessages[0]}</p>}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="" style={{ marginTop: "50px" }}>
              {output && (
                <div>
                  <img src={output} alt="Converted" />
                  <a
                    href={output}
                    download={outputFileName || "error"}
                    className="btn btn-outline-primary"
                    style={{ marginTop: "10px" }}
                  >
                    変換した画像のダウンロード
                  </a>
                </div>
              )}
            </div>
            <div
              className="card"
              style={{ marginTop: "20px", marginBottom: "70px" }}
            >
              <div className="card-header">注意事項</div>
              <div className="card-body">
                <p className="card-text">
                  画像ファイルにより、変換しきれない画像や利用できないファイルがある可能性があります。
                </p>
                <p className="card-text">
                  ご利用にあたって生じた損害を当社は一切の責任を持つことができません。ご容赦ください。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContentsCard;
