import HeaderStyle from "./Header.module.scss";
import logoSvg from "../../svg/logo.svg";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFixed } from "../../slice/headerSlice";
import { toggle } from "../../slice/navButtonSlice";
import ROUTES from "../../constants/routes";

const Header = () => {
  const dispatch = useDispatch();
  const isFixed = useSelector((state) => state.header.isFixed);
  const isNavOpen = useSelector((state) => state.navButton.isOpen);

  const [headerInitialOffset, setHeaderInitialOffset] = useState(0);

  const navFunc = () => {
    dispatch(toggle());
    document.querySelector("html").classList.toggle(`${HeaderStyle.open}`);
  };

  useEffect(() => {
    const header = document.querySelector(`.${HeaderStyle.header}`);
    const headerOffset = header ? header.offsetTop : 0;
    setHeaderInitialOffset(headerOffset);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // スクロール位置がヘッダーの初期位置より下にある場合は固定
      if (window.scrollY > headerInitialOffset) {
        dispatch(setFixed(true));
      } else {
        // スクロール位置がヘッダーの初期位置より上にある場合は固定解除
        dispatch(setFixed(false));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [headerInitialOffset, dispatch]);

  // ビューポートの高さと横幅を状態として保持
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  // 1vhをピクセルに変換
  const oneVhInPx = viewportHeight / 100;
  // 1vwをピクセルに変換
  const oneVwInPx = viewportWidth / 100;

  useEffect(() => {
    // ウィンドウのリサイズ時にビューポートの高さと横幅を更新する関数
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    // ウィンドウのリサイズイベントにリスナーを登録
    window.addEventListener("resize", handleResize);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const pageTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <header
      className={`${HeaderStyle.header} ${
        isFixed ? HeaderStyle.fixedHeader : ""
      }`}
      style={{ "--vh": `${oneVhInPx}px`, "--vw": `${oneVwInPx}px` }}
    >
      <div class={HeaderStyle.container}>
        <div className={HeaderStyle.headerLogo}>
          <Link
            to="/"
            className={HeaderStyle.headerLogoTarget}
            onClick={pageTop}
          >
            <svg role="img" viewBox="0 0 1139 325">
              <title>Elbaup!!</title>
              <use xlinkHref={`${logoSvg}#logo-hr`}></use>
            </svg>
            <h2>Elbaup!!</h2>
          </Link>
        </div>
        <nav className={HeaderStyle.headerNav}>
          <ul className={`${HeaderStyle.headerNavList} ${HeaderStyle.uFonten}`}>
            <li className={HeaderStyle.headerNavListItem}>
              <Link
                to={ROUTES.ABOUT}
                className={`${HeaderStyle.target} ${HeaderStyle.uTargetfilter} `}
              >
                about
              </Link>
            </li>
            <li className={HeaderStyle.headerNavListItem}>
              <Link
                to={ROUTES.SERVICE}
                className={`${HeaderStyle.target} ${HeaderStyle.uTargetfilter} ${HeaderStyle.isHashOnclick}`}
              >
                service
              </Link>
            </li>
            <li className={HeaderStyle.headerNavListItem}>
              <Link
                to={ROUTES.CONTACT}
                className={`${HeaderStyle.target} ${HeaderStyle.uTargetfilter} `}
              >
                contact
              </Link>
            </li>
          </ul>
        </nav>
        <button
          type="button"
          className={HeaderStyle.navButton}
          onClick={navFunc}
        >
          <span className={HeaderStyle.srOnly}>MENU</span>
        </button>
      </div>
    </header>
  );
};

export default Header;
