// features/loading/loadingSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoadingState {
  isFirstLoading: boolean;
}

const initialState: LoadingState = {
  isFirstLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setFirstLoadCompleted: (state, action: PayloadAction<boolean>) => {
      state.isFirstLoading = action.payload;
    },
  },
});

export const { setFirstLoadCompleted } = loadingSlice.actions;
export default loadingSlice.reducer;
