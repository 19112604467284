import Style from "./OcrConv.module.scss";
import Tesseract from "tesseract.js";
import React, { useState, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";

const OcrConvPage = () => {
  const [ocrText, setOcrText] = useState(""); // OCR結果を保存するための状態
  const [isLoading, setIsLoading] = useState(false); // 処理中フラグ

  // イベントの型を `React.ChangeEvent<HTMLInputElement>` に変更
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setIsLoading(true);
      convertImageToBinary(file);
    }
  };

  const convertImageToBinary = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      // イベントハンドラの型を `ProgressEvent<FileReader>` に変更し、
      // `e.target` が `null` でないことを確認
      const result = e.target?.result;
      if (typeof result === "string") {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (!ctx) {
            console.error("Failed to get canvas context");
            setIsLoading(false);
            return;
          }
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // 画像をグレースケールに変換
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const data = imageData.data;
          for (let i = 0; i < data.length; i += 4) {
            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = avg;
            data[i + 1] = avg;
            data[i + 2] = avg;
          }
          ctx.putImageData(imageData, 0, 0);

          // CanvasをBlobに変換してOCRを実行
          canvas.toBlob((blob) => {
            if (blob) performOcr(blob);
          });
        };
        img.src = result;
      } else {
        console.error("FileReader did not return a string.");
        setIsLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const performOcr = (blob: Blob) => {
    Tesseract.recognize(blob, "eng+jpn", {
      logger: (m) => console.log(m),
    }).then(({ data: { text } }) => {
      setOcrText(text);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={Style.ContentsContainer}>
      <Header2 />
      <div style={{ height: "78px" }}></div>
      <div className={Style.mainContent}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ marginBottom: "20px" }}
        />
        {isLoading ? <p>読み取り中...</p> : <p>読み取り結果: {ocrText}</p>}
      </div>
      <Footer />
    </div>
  );
};

export default OcrConvPage;
