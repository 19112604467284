import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import TopPage from "./pages/TopPage";
import MoonPage from "./pages/MoonPage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicePage from "./pages/ServicePage";
import ContactPage from "./pages/ContactPage";
import MoonEulaPage from "./pages/MoonEulaPage";
import LoadingScreen from "./components/Load/LoadingScreen";
import ImageOutputPage from "./pages/ImageOutputPage";
import "bootstrap/dist/css/bootstrap.min.css";
import ImageConvPage from "./pages/ImageConvPage";
import VideoConvPage from "./pages/VideoConvPage";
import MusicConvPage from "./pages/MusicConvPage";
import { useSelector, useDispatch } from "react-redux";
import { setFirstLoadCompleted } from "./slice/loadingSlice";
import { RootState } from "./reduxStore/storeType";
import ROUTES from "./constants/routes";
import OcrConvPage from "./pages/OcrConvPage";

function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const isFirstLoading = useSelector(
    (state: RootState) => state.loading.isFirstLoading
  );

  useEffect(() => {
    // トップページかどうかをチェック
    if (location.pathname === ROUTES.HOME) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [location]);

  const handleLoadingComplete = () => {
    setIsLoading(false);
    dispatch(setFirstLoadCompleted(true));
    // ローディング終了時にスクロール可能に戻す
    document.body.style.overflow = "";
  };

  return (
    <>
      {isLoading && !isFirstLoading && (
        <LoadingScreen onLoadingComplete={handleLoadingComplete} />
      )}
      <Routes>
        <Route path={ROUTES.CONTACT} element={<ContactPage />} />
        <Route path={ROUTES.SERVICE} element={<ServicePage />} />
        <Route path={ROUTES.ABOUT} element={<AboutUsPage />} />
        <Route path={ROUTES.HOME} element={<TopPage />} />
        <Route path={ROUTES.MOON} element={<MoonPage />} />
        <Route path={ROUTES.MOON_EULA} element={<MoonEulaPage />} />
        <Route path={ROUTES.EXCEL_OUTPUT_IMAGE} element={<ImageOutputPage />} />
        <Route path={ROUTES.IMAGE_CONVERSION} element={<ImageConvPage />} />
        <Route path={ROUTES.VIDEO_CONVERSION} element={<VideoConvPage />} />
        <Route path={ROUTES.MUSIC_CONVERSION} element={<MusicConvPage />} />
        <Route path={ROUTES.ORC} element={<OcrConvPage />} />
      </Routes>
    </>
  );
}

export default App;
