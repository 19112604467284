import "../css/ContactPage.css";
import ContactForm from "../components/ContactForm";
import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ContactContainer">
      <Header2 />
      <div style={{ height: "78px" }}></div>
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactPage;
