import Style from "./ContentPage.module.scss";
import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";
import ImageContentsCard from "../components/contents/ImageContentsCard";

const ImageConvPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={Style.ContentsContainer}>
      <Header2 />
      <div style={{ height: "78px" }}></div>
      <div className={Style.mainContent}>
        <ImageContentsCard />
      </div>
      <Footer />
    </div>
  );
};

export default ImageConvPage;
