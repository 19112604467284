import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface RandomTextAnimationProps {
  targetText: string;
  duration?: number;
  interval?: number;
}

const RandomTextAnimation: React.FC<RandomTextAnimationProps> = ({
  targetText,
  duration = 2000,
  interval = 50,
}) => {
  const [displayText, setDisplayText] = useState<string>("");
  const animationRef = useRef(null);

  useEffect(() => {
    const triggerElement = animationRef.current;
    if (!triggerElement) return;

    const scrollTrigger = ScrollTrigger.create({
      trigger: triggerElement,
      start: "top bottom",
      onEnter: startAnimation,
    });

    function startAnimation() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let currentText = Array.from({ length: targetText.length })
        .map(() =>
          characters.charAt(Math.floor(Math.random() * characters.length))
        )
        .join("");

      let i = 0;
      const intervalId = setInterval(() => {
        if (i < targetText.length) {
          currentText =
            currentText.substring(0, i) +
            targetText[i] +
            currentText.substring(i + 1);
        }

        setDisplayText(currentText);
        i++;

        if (i > targetText.length) {
          clearInterval(intervalId);
        }
      }, interval);

      return () => clearInterval(intervalId);
    }

    return () => {
      scrollTrigger.kill();
    };
  }, [targetText, duration, interval]);

  return (
    <div ref={animationRef}>
      <p>{displayText}</p>
    </div>
  );
};

export default RandomTextAnimation;
