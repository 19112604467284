import Site from "../atoms/Site";
import Nav from "../atoms/Nav";
import Style from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={Style.footer}>
      <div className={Style.container}>
        <div className={Style.siteBox}>
          <Site />
          <Nav />
        </div>
        <div className={Style.address}>
          <p>569-1146</p>
          <p>大阪府高槻市赤大路町 11-8</p>
        </div>
        <p className={Style.twitterLink}>
          <a href="https://twitter.com/elbaup_com">Twitter</a>
        </p>
        <p className={Style.copyLight}>©2023 Elbaup. All right reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
