const ROUTES = {
  HOME: "/",
  CONTACT: "/contact",
  SERVICE: "/service",
  ABOUT: "/about",
  MOON: "/moon",
  MOON_EULA: "/moon-eula",
  EXCEL_OUTPUT_IMAGE: "/excel-output-image",
  VIDEO_CONVERSION: "/video-conversion",
  IMAGE_CONVERSION: "/image-conversion",
  MUSIC_CONVERSION: "/music-conversion",
  TAX_CALC: "/calc",
  ORC: "/orc",
};

export default ROUTES;
