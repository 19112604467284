import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import Style from "./AboutHero.module.scss";

const AboutHero = () => {
  const animationVariants = {
    hidden: {
      opacity: 0,
      x: "-100%",
      y: "100%",
      scale: 0.5,
    },
    hiddenUnder: {
      opacity: 0,
      x: "0%",
      y: "100%",
    },
    hiddenUnderHarf: {
      opacity: 0,
      x: "0%",
      y: "50%",
    },
    visibleH2: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1.1,
      transition: {
        duration: 0.5,
      },
    },
    visibleP: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1.1,
      transition: {
        duration: 0.5,
        delay: 0.5, // h2のアニメーションのdurationと同じ
      },
    },
    visiblePHead: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 1, // h2のアニメーションのdurationと同じ
      },
    },
    visibleP1: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 1.5, // h2のアニメーションのdurationと同じ
      },
    },
    visibleP2: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 2, // h2のアニメーションのdurationと同じ
      },
    },
    visibleP3: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 2.5, // h2のアニメーションのdurationと同じ
      },
    },
    commonStyle: {
      marginLeft: "5.33333vw",
      marginRight: "5.33333vw",
    },
  };

  return (
    <div className={Style.abouthero}>
      <div className={Style.container}>
        <div className={Style.heroTitle}>
          <motion.h2
            initial="hidden"
            animate="visibleH2"
            variants={animationVariants}
            style={animationVariants.commonStyle}
          >
            About Us
          </motion.h2>
          <motion.p
            initial="hiddenUnder"
            animate="visibleP"
            variants={animationVariants}
            style={animationVariants.commonStyle}
          >
            私たちについて
          </motion.p>
        </div>
        <div className={Style.heroDesc}>
          Elbaupとは
          <span style={{ color: "rgba(115, 248, 224, 1)" }}>E</span>very
          <span style={{ color: "rgba(115, 248, 224, 1)" }}> l</span>ittle
          <span style={{ color: "rgba(115, 248, 224, 1)" }}> b</span>it
          <span style={{ color: "rgba(115, 248, 224, 1)" }}> a</span>dds
          <span style={{ color: "rgba(115, 248, 224, 1)" }}> up</span>
          の頭文字からとっています。
          <p className={Style.p1}>
            翻訳すると「少しでも積み重ねると大きな結果に繋がる」といったニュアンスです。
          </p>
          <p className={Style.p2}>私達は何かを成し遂げるとき</p>
          <p className={Style.p3}>
            実際は日々の積み重ねで大きな成果を創り上げています。
          </p>
          <p className={Style.p4}>
            私たちは小さな努力を続けてより良いサービスを提供し続けます。
          </p>
        </div>
        <div className={Style.heroTitle}>
          <h2 style={animationVariants.commonStyle}>Image</h2>
        </div>
        <div className={Style.aboutImgContiner}>
          <div className={Style.aboutDesc}>
            <p className={Style.p1}>
              技術者であることを誇りに社会に役立つサービスを
            </p>
            <p className={Style.p2}>困っている人を助けたい</p>
            <p className={Style.p3}>
              私たちに何ができるのか日々、考えています。
            </p>
            <p className={Style.p4}>小さな一歩が大きな一歩になることを夢見て</p>
            <p className={Style.p5} style={{ marginBottom: "50px" }}></p>
            <p>代表　比嘉 龍</p>
          </div>
          <div className={Style.imgSide}>
            <img src="/img/prof.PNG" alt="profile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
