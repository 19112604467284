// headerSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    isFixed: false,
  },
  reducers: {
    setFixed: (state, action) => {
      state.isFixed = action.payload;
    },
  },
});

export const { setFixed } = headerSlice.actions;
export default headerSlice.reducer;
