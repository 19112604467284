import Imagelogo from "../Imagelogo";
import Style from "./Site.module.scss";

const Site = () => {
  return (
    <div className={Style.site}>
      <Imagelogo />
      <p className={Style.corp}>Elbaup!!</p>
    </div>
  );
};

export default Site;
