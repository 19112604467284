import React, { useEffect } from "react";
import style from "./LoadingScreen.module.scss";
import Vivus from "vivus";
import animeLogoSvg from "../../svg/animeLogo.svg";
import { gsap } from "gsap";

interface LoadingScreenProps {
  onLoadingComplete: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ onLoadingComplete }) => {
  const didEffect = React.useRef(false);
  const loadingContainerRef = React.useRef<HTMLDivElement>(null);
  const curtainRef = React.useRef<HTMLDivElement>(null);
  const animeLogoRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (didEffect.current) return;
    didEffect.current = true;

    // スタイル設定
    disableScroll();

    // Vivusアニメーションの初期化
    initVivus();

    // GSAPアニメーションの実行
    runAnimations(onLoadingComplete);
  }, [onLoadingComplete]);

  // スクロールを無効にする
  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  // Vivusアニメーションの初期化
  const initVivus = () => {
    new Vivus(`${style.animeLogo}`, {
      type: "oneByOne",
      duration: 450,
      file: animeLogoSvg,
    });
  };

  // GSAPアニメーションの実行
  const runAnimations = (onLoadingComplete: () => void) => {
    const tl = gsap.timeline({ onComplete: onLoadingComplete });

    // 1. 最初にカーテンが下がるアニメーション
    tl.fromTo(
      curtainRef.current,
      { y: "-100%" },
      { delay: 3.3, duration: 0.3, y: "0%", ease: "power2.inOut" }
    );
    tl.set(loadingContainerRef.current, { backgroundColor: "transparent" });

    // 2. 同じカーテンをさらに別の位置に移動させるアニメーション
    tl.to(
      curtainRef.current,
      { duration: 0.5, y: "100%", ease: "power2.inOut" },
      "+=0.0001" // 1つ目のアニメーション後に少し遅延して開始
    ).to(
      animeLogoRef.current,
      { duration: 0.1, autoAlpha: 0 }, // animeLogoを非表示にする
      "-=0.5"
    );
  };

  return (
    <div className={style.loadingContainer} ref={loadingContainerRef}>
      <div id={style.animeLogo} ref={animeLogoRef}></div>
      <div className={style.curtain} ref={curtainRef}></div>
    </div>
  );
};

export default LoadingScreen;
