import React, { useState, useEffect, useRef } from "react";
import Style from "./VideoContentsCard.module.scss";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { Button, Form, Accordion, Tab, Tabs } from "react-bootstrap";

const ffmpeg = createFFmpeg({ log: true });

const VideoContentsCard: React.FC = () => {
  const [video, setVideo] = useState<File | null>(null);
  const [output, setOutput] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // ロード状態管理
  const [logMessages, setLogMessages] = useState<string[]>([]); // ログメッセージ用の状態変数
  const [bitrate, setBitrate] = useState("default");
  const [resolution, setResolution] = useState("default"); // 解像度のための状態変数
  const [isConverting, setIsConverting] = useState<boolean>(false); // 変換ステータスの判定
  const [outputFileName, setOutputFileName] = useState("");

  useEffect(() => {
    const load = async () => {
      if (!ffmpeg.isLoaded()) {
        setIsLoading(true);
        await ffmpeg.load();
        setIsLoading(false);
      }
    };
    // ffmpegのログを処理する関数をセットアップ
    ffmpeg.setLogger(({ type, message }) => {
      if (message === "ffmpeg-core loaded") {
        setLogMessages(["未処理"]);
      } else if (message === "loading ffmpeg-core") {
        setLogMessages(["未処理"]);
      } else {
        setLogMessages([`${message}`]); // 最新のメッセージのみを保持
      }
    });
    load();
  }, []);

  const convertVideo = async (
    fileType: string,
    videoCodec: string | null,
    audioCodec: any | null
  ) => {
    if (video && !isConverting) {
      setIsConverting(true);
      const originalFileName = video.name.split(".").slice(0, -1).join(".");
      const outputFileName = `${originalFileName}.${fileType}`;
      setOutputFileName(outputFileName);

      ffmpeg.FS("writeFile", "input", await fetchFile(video));

      let ffmpegArgs = ["-i", "input"];
      if (videoCodec) {
        ffmpegArgs.push("-vcodec", videoCodec);
      }
      if (audioCodec) {
        ffmpegArgs.push("-acodec", audioCodec);
      }

      switch (bitrate) {
        case "low":
          ffmpegArgs.push("-b:v", "1000k", "-b:a", "96k");
          break;
        case "medium":
          ffmpegArgs.push("-b:v", "1500k", "-b:a", "128k");
          break;
        case "high":
          ffmpegArgs.push("-b:v", "2500k", "-b:a", "192k");
          break;
      }

      if (resolution !== "default") {
        ffmpegArgs.push("-s", resolution);
      }

      ffmpegArgs.push(outputFileName);

      try {
        await ffmpeg.run(...ffmpegArgs);
        const data = ffmpeg.FS("readFile", outputFileName);
        const url = URL.createObjectURL(
          new Blob([data.buffer], { type: `video/${fileType}` })
        );
        setOutput(url);
      } catch (error) {
        setLogMessages(["変換エラー"]);
      } finally {
        setIsConverting(false);
      }
    }
  };

  const convertToMp4 = () => convertVideo("mp4", null, null);
  const convertToAvi = () => convertVideo("avi", null, null);
  const convertToMov = () => convertVideo("mov", null, null);
  const convertToWmv = () => convertVideo("wmv", null, null);
  const convertToWebm = () => convertVideo("webm", null, null);
  const convertToFlv = () => convertVideo("flv", null, null);
  const convertToMp3 = async () => {
    if (video && !isConverting) {
      setIsConverting(true); // 変換開始時に状態を設定

      // 元のファイル名を拡張子なしで取得
      const originalFileName = video.name.split(".").slice(0, -1).join(".");
      const outputFileName = `${originalFileName}.mp3`;
      setOutputFileName(outputFileName);

      // ファイルをffmpegに読み込ませる
      ffmpeg.FS("writeFile", "input", await fetchFile(video));

      // ビットレートに基づくFFmpegのコマンドを変更
      let ffmpegArgs = ["-i", "input", "-vn"];

      switch (bitrate) {
        case "128k":
          ffmpegArgs.push("-ab", "128k");
          break;
        case "192k":
          ffmpegArgs.push("-ab", "192k");
          break;
        case "320k":
          ffmpegArgs.push("-ab", "320k");
          break;
        default:
          // デフォルト設定の場合は特にビットレートを指定しない
          break;
      }

      ffmpegArgs.push(outputFileName);

      try {
        // 変換コマンドを実行
        await ffmpeg.run(...ffmpegArgs);

        // 出力ファイルを取得
        const data = ffmpeg.FS("readFile", outputFileName);

        // Blobとして出力ファイルを生成し、URLをセット
        const url = URL.createObjectURL(
          new Blob([data.buffer], { type: "audio/mpeg" })
        );
        setOutput(url);
      } catch (error) {
        setLogMessages(["変換エラー"]);
      } finally {
        setIsConverting(false); // 変換終了時に状態をリセット
      }
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={Style.contents}>
      <div className={Style.container}>
        <div className={Style.titletext}>
          <h2>動画ファイルの変換</h2>
          <div className={Style.textDesc}>
            <p>
              利用者のプライバシーを守る為にファイル・動画をサーバへ送信しておりません。
            </p>
            <p>
              すべてお使いのブラウザ内（PC/携帯端末）で変換処理をしていますので安心してご利用ください。
            </p>
          </div>
        </div>
        <div className={Style.convertBox}>
          <div>
            <input
              type="file"
              onChange={(e) =>
                setVideo(e.target.files ? e.target.files[0] : null)
              }
            />
            <Tabs
              defaultActiveKey="mp4"
              id="uncontrolled-tab-example"
              className="mb-3 mt-3"
            >
              <Tab eventKey="mp4" title="mp4">
                <div className="">
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低画質(10Mbps)</option>
                        <option value="medium">中画質(15Mbps)</option>
                        <option value="high">高画質(25Mbps)</option>
                      </Form.Select>
                      {/* 解像度の選択 */}
                      <Form.Group controlId="resolutionSelect">
                        <Form.Label>解像度の選択</Form.Label>
                        <Form.Select
                          aria-label="Resolution select"
                          style={{ width: "200px" }} // 希望の横幅に設定
                          value={resolution}
                          onChange={(e) => setResolution(e.target.value)}
                        >
                          <option value="default">指定しない</option>
                          <option value="640x360">SD (640x360)</option>
                          <option value="1280x720">HD (1280x720)</option>
                          <option value="1920x1080">Full HD (1920x1080)</option>
                        </Form.Select>
                      </Form.Group>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToMp4}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    mp4に変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
              <Tab eventKey="avi" title="avi">
                <div>
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低画質(10Mbps)</option>
                        <option value="medium">中画質(15Mbps)</option>
                        <option value="high">高画質(25Mbps)</option>
                      </Form.Select>
                      {/* 解像度の選択 */}
                      <Form.Group controlId="resolutionSelect">
                        <Form.Label>解像度の選択</Form.Label>
                        <Form.Select
                          aria-label="Resolution select"
                          style={{ width: "200px" }} // 希望の横幅に設定
                          value={resolution}
                          onChange={(e) => setResolution(e.target.value)}
                        >
                          <option value="default">指定しない</option>
                          <option value="640x360">SD (640x360)</option>
                          <option value="1280x720">HD (1280x720)</option>
                          <option value="1920x1080">Full HD (1920x1080)</option>
                        </Form.Select>
                      </Form.Group>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToAvi}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    aviに変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
              <Tab eventKey="mov" title="mov">
                <div>
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低画質(10Mbps)</option>
                        <option value="medium">中画質(15Mbps)</option>
                        <option value="high">高画質(25Mbps)</option>
                      </Form.Select>
                      {/* 解像度の選択 */}
                      <Form.Group controlId="resolutionSelect">
                        <Form.Label>解像度の選択</Form.Label>
                        <Form.Select
                          aria-label="Resolution select"
                          style={{ width: "200px" }} // 希望の横幅に設定
                          value={resolution}
                          onChange={(e) => setResolution(e.target.value)}
                        >
                          <option value="default">指定しない</option>
                          <option value="640x360">SD (640x360)</option>
                          <option value="1280x720">HD (1280x720)</option>
                          <option value="1920x1080">Full HD (1920x1080)</option>
                        </Form.Select>
                      </Form.Group>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToMov}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    movに変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
              <Tab eventKey="wmv" title="wmv">
                <div>
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低画質(10Mbps)</option>
                        <option value="medium">中画質(15Mbps)</option>
                        <option value="high">高画質(25Mbps)</option>
                      </Form.Select>
                      {/* 解像度の選択 */}
                      <Form.Group controlId="resolutionSelect">
                        <Form.Label>解像度の選択</Form.Label>
                        <Form.Select
                          aria-label="Resolution select"
                          style={{ width: "200px" }} // 希望の横幅に設定
                          value={resolution}
                          onChange={(e) => setResolution(e.target.value)}
                        >
                          <option value="default">指定しない</option>
                          <option value="640x360">SD (640x360)</option>
                          <option value="1280x720">HD (1280x720)</option>
                          <option value="1920x1080">Full HD (1920x1080)</option>
                        </Form.Select>
                      </Form.Group>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToWmv}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    wmvに変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
              <Tab eventKey="webm" title="webm">
                <div>
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低画質(10Mbps)</option>
                        <option value="medium">中画質(15Mbps)</option>
                        <option value="high">高画質(25Mbps)</option>
                      </Form.Select>
                      {/* 解像度の選択 */}
                      <Form.Group controlId="resolutionSelect">
                        <Form.Label>解像度の選択</Form.Label>
                        <Form.Select
                          aria-label="Resolution select"
                          style={{ width: "200px" }} // 希望の横幅に設定
                          value={resolution}
                          onChange={(e) => setResolution(e.target.value)}
                        >
                          <option value="default">指定しない</option>
                          <option value="640x360">SD (640x360)</option>
                          <option value="1280x720">HD (1280x720)</option>
                          <option value="1920x1080">Full HD (1920x1080)</option>
                        </Form.Select>
                      </Form.Group>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToWebm}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    webmに変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
              <Tab eventKey="flv" title="flv">
                <div>
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低画質(10Mbps)</option>
                        <option value="medium">中画質(15Mbps)</option>
                        <option value="high">高画質(25Mbps)</option>
                      </Form.Select>
                      {/* 解像度の選択 */}
                      <Form.Group controlId="resolutionSelect">
                        <Form.Label>解像度の選択</Form.Label>
                        <Form.Select
                          aria-label="Resolution select"
                          style={{ width: "200px" }} // 希望の横幅に設定
                          value={resolution}
                          onChange={(e) => setResolution(e.target.value)}
                        >
                          <option value="default">指定しない</option>
                          <option value="640x360">SD (640x360)</option>
                          <option value="1280x720">HD (1280x720)</option>
                          <option value="1920x1080">Full HD (1920x1080)</option>
                        </Form.Select>
                      </Form.Group>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToFlv}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    flvに変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
              <Tab eventKey="mp3" title="mp3">
                <div>
                  <Form>
                    {/* ビットレートの選択 */}
                    <Form.Group controlId="bitrateSelect">
                      <Form.Label>ビットレートの選択</Form.Label>
                      <Form.Select
                        aria-label="Bitrate select"
                        style={{ width: "200px" }}
                        value={bitrate}
                        onChange={(e) => setBitrate(e.target.value)}
                      >
                        <option value="default">指定しない</option>
                        <option value="low">低音質(128k)</option>
                        <option value="medium">中音質(192k)</option>
                        <option value="high">最高音質(320k)</option>
                      </Form.Select>
                    </Form.Group>
                  </Form>

                  <Button
                    variant="success"
                    style={{ marginTop: "10px" }}
                    onClick={convertToMp3}
                    disabled={isConverting} // 変換中はボタンを無効化
                  >
                    mp3に変換
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    onClick={reloadPage}
                  >
                    変換の中止
                  </Button>
                </div>
              </Tab>
            </Tabs>
            <div className="logs" style={{ marginTop: "10px" }}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>変換状況の表示</Accordion.Header>
                  <Accordion.Body>
                    {logMessages.length > 0 && <p>{logMessages[0]}</p>}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="" style={{ marginTop: "50px" }}>
              <div>
                {output && (
                  <div>
                    <video controls src={output} />
                    <a
                      href={output}
                      download={outputFileName || "error"} // 任意のファイル名
                      className="btn btn-outline-primary" // Bootstrapのボタンスタイルを適用
                      style={{ marginTop: "10px" }}
                    >
                      変換した動画のダウンロード
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div className="card">
              <div className="card-header">個人情報について</div>
              <div className="card-body">
                <p className="card-text">
                  ここで使用した動画ファイルとその内容は、当社のサーバにアップロードされません。
                </p>
                <p className="card-text">
                  利用者のパソコンやスマホのブラウザ内でのみ処理が行われます。
                </p>
                <p className="card-text">
                  当社がどのように利用したかは一切知ることはできません。
                </p>
              </div>
            </div>
            <div
              className="card"
              style={{
                marginTop: "20px",
                marginBottom: "70px",
              }}
            >
              <div className="card-header">注意事項</div>
              <div className="card-body">
                <p className="card-text">
                  動画ファイルにより、変換しきれない動画や利用できないファイルがある可能性があります。
                </p>
                <p className="card-text">
                  ご利用にあたって生じた損害を当社は一切の責任を持つことができません。ご容赦ください。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoContentsCard;
