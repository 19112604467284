export const interpolateColor = (
  color1: string,
  color2: string,
  factor: number = 0.5
): string => {
  // factorを0-0.5と0.5-1の範囲で往復するように調整
  factor = factor > 0.5 ? 1 - factor : factor;
  factor *= 2; // 0-0.5の範囲を0-1に拡張

  const result = color1
    .slice(1)
    .match(/.{2}/g)!
    .map((hex, i) =>
      Math.floor(
        parseInt(hex, 16) +
          (parseInt(color2.slice(1).match(/.{2}/g)![i], 16) -
            parseInt(hex, 16)) *
            factor
      )
        .toString(16)
        .padStart(2, "0")
    );
  return `#${result.join("")}`;
};
