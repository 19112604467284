import "../css/ServicePage.css";
import ServiceHero from "../components/ServiceHero";
import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";

const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="serviceContainer">
      <Header2 />
      <div style={{ height: "78px" }}></div>

      <ServiceHero />

      <Footer />
    </div>
  );
};

export default ServicePage;
