import Hero from "../components/Hero/Hero";
import Header from "../components/Header/Header";
import Service from "../components/contents/Service";
import Contact from "../components/contents/Contact";
import Footer from "../components/Footer/Footer";
import Style from "./TopPage.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, NavState } from "../state/rootState";
import { interpolateColor } from "../utile/colorUtils";
import MenuOverlay from "../components/Overlay/MenuOverlay";
import ContentsCard from "../components/contents/ContentsCard";

// GSAPプラグインの登録
gsap.registerPlugin(ScrollTrigger);

const TopPage = () => {
  const isFixed = useSelector((state: RootState) => state.header.isFixed);
  const isNavOpen = useSelector((state: NavState) => state.navButton.isOpen);

  const [scrollTween, setScrollTween] = useState<GSAPTween | null>(null);

  const horizontalSectionRef = useRef<HTMLDivElement>(null);
  const didEffect = React.useRef(false);

  useEffect(() => {
    if (didEffect.current) return;
    didEffect.current = true;

    if (horizontalSectionRef.current) {
      // gsap.utils.toArray で HTMLElement[] 型の配列を取得
      const panels: HTMLElement[] = gsap.utils.toArray(
        "." + Style.panelContainer
      ) as HTMLElement[];
      // 各パネルの幅とマージンを合計して、スクロール領域の総長さを計算
      let totalPanelsWidth = 0;
      panels.forEach((panel) => {
        const style = window.getComputedStyle(panel);
        const marginLeft = parseFloat(style.marginLeft);
        const marginRight = parseFloat(style.marginRight);
        totalPanelsWidth += panel.offsetWidth + marginLeft + marginRight;
      });

      // グラデーションの開始色と終了色
      const startColor = "#f4f3ef";
      const endColor = "#ffcce6";

      const tween = gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        //        maker: true,
        scrollTrigger: {
          trigger: horizontalSectionRef.current, // アニメーションの対象となる要素
          pin: true, // 要素を固定する
          scrub: 1, // スクロールとアニメーションを同期させる。数値で秒数の設定に
          // markers: true,
          end: () => "+=" + totalPanelsWidth, // アニメーションの終了タイミング
          onUpdate: (self) => {
            const progress = self.progress; // 0から1までの進行度
            // 進行度に基づいてグラデーション色を計算
            const currentColor = interpolateColor(
              startColor,
              endColor,
              progress
            );
            document.body.style.setProperty(
              "--bg-gradient-color",
              currentColor
            );
          },
        },
      });
      setScrollTween(tween);
    }
  }, []);

  return (
    <>
      {isNavOpen && <MenuOverlay />}
      <Hero />
      <Header />
      <div className={`${Style.contents} ${isFixed ? Style.fixedHeader : ""}`}>
        <ContentsCard />
        <div className={Style.wrapper} ref={horizontalSectionRef}>
          <div className={Style.panelContainer}>
            <Service />
          </div>
          <div className={Style.panelContainer}>
            <Contact scrollTween={scrollTween} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TopPage;
